import { Ref, ref } from "@nuxtjs/composition-api";
import { capitalize, iItem, isTrue, logger } from "@loadsure/utils";
import { decimal, integer, required } from "vuelidate/lib/validators";
import Vue from "vue";
import type { Validation } from "vuelidate"

export const errorStateComputed = {
  invalid(this: { $v: Validation }) {
    return !this.$v.$pending && this.$v.$invalid;
  },
  anyDirty(this: { $v: Validation }) {
    return this.$v.$anyDirty;
  },
  dirty(this: { $v: Validation }) {
    return !this.$v.$pending && this.$v.$anyDirty;
  },
  error(this: { $v: Validation }) {
    return this.$v.$error;
  }
};

export const errorStateWatchers = {
  invalid: {
    handler(this: { $emit: Vue["$emit"] }, value: unknown) {
      if (this) {
        this?.$emit("invalid", value);
      } else {
        logger.error(
          "Object reference 'this' is undefined on error state watcher handler"
        );
      }
    },
    immediate: true
  },
  anyDirty: {
    handler(this: { $emit: Vue["$emit"] }, value: unknown) {
      this.$emit("anyDirty", value);
    },
    immediate: true
  },
  dirty: {
    handler(this: { $emit: Vue["$emit"] }, value: unknown) {
      this.$emit("dirty", value);
    },
    immediate: true
  },
  error: {
    handler(this: { $emit: Vue["$emit"] }, value: unknown) {
      this.$emit("error", value);
    },
    immediate: true
  }
};

export function focusErrorField($v: Vue, $refs: Vue["$refs"]) {
  const firstInvalid = Object.keys($v)
    .filter((key) => !key.includes("$"))
    .find((key) => $v[key].$invalid && $refs[key]);

  if (firstInvalid) {
    const first = $refs[firstInvalid]
    if (!first) return true
    // eslint-disable-next-line no-underscore-dangle
    if ("_name" in first && first._name === "<YesNoRadioButton>") {
      if (first && "selfFocus" in first && typeof first.selfFocus === "function") {
        first.selfFocus();
      }
    } else if ("focus" in first && typeof first.focus === "function") {
        first.focus();
    }
    return true;
  }
  return false;
}

export function focusErrorRef($refs) {
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const firstRef in $refs) {
    if (Array.isArray($refs[firstRef])) {
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const secondRef in $refs[firstRef]) {
        if (
          $refs[firstRef][secondRef].invalid ||
          $refs[firstRef][secondRef].$v.$invalid
        ) {
          $refs[firstRef][secondRef].focus();
          return true;
        }
      }
    } else if ($refs[firstRef].invalid) {
      $refs[firstRef].focus();
      return true;
    }
  }
  return false;
}

export async function uniqueId(this: {
  form: { id: string }
  editedIndex: number
  itemExists: (id: string) => Promise<boolean>
}) {
  return (
    !this.form.id ||
    this.editedIndex >= 0 ||
    !(await this.itemExists(this.form.id))
  );
}

export function touchFields(this: Vue) {
  this.$v.$touch();
}

export function validateAll(this: Vue, $v: Validation) {
  const $vuelidate = $v ?? this.$v;
  $vuelidate.$touch();

  return new Promise((resolve) => {
    if (!$vuelidate.$pending) {
      resolve(!$vuelidate.$invalid);
    } else {
      const unwatch = this.$watch(
        () => !$vuelidate.$pending,
        (isNotPending) => {
          if (isNotPending) {
            unwatch();
            resolve(!$vuelidate.$invalid);
          }
        }
      );
    }
  });
}

export function inList<T>(
  value: T | null | undefined,
  list: T[] | null | undefined
) {
  return value == null || !list || list.length === 0 || list.includes(value);
}

export function inItemList<T>(
  value: T | null | undefined,
  itemList: iItem<T>[] | null | undefined
) {
  return (
    value == null ||
    !itemList ||
    itemList.length === 0 ||
    itemList.some((item) => item.value === value)
  );
}

export function isNonEmptyFile(file?: { size: number }) {
  return (file?.size || 0) > 0;
}

export function positiveNumber(value: unknown) {
  return value === undefined || value === null || value === "" || value > 0;
}

export function positiveNumberAndZero(value: unknown) {
  return value === undefined || value === null || value === "" || value >= 0;
}

export const noNegative = {
  integer,
  positiveNumberAndZero
};

export const positiveInteger = {
  integer,
  positiveNumber
};

export const requiredPositiveInteger = {
  required,
  ...positiveInteger
};

export const positiveDecimal = {
  required,
  decimal,
  positiveNumber
};

export const percentage = {
  decimal,
  positiveNumber,
  function(value) {
    return (
      value === undefined || value === null || (value >= 0 && value <= 100)
    );
  }
};
export const requiredPositiveDecimal = {
  ...positiveDecimal
};

export function isTrueIfSet(value) {
  return value === undefined || value === null || value === true;
}

const mapErrors = (field: Validation | undefined, errorMap: { [error: string]: string }) =>
  field && field.$error
    ? Object.entries(errorMap)
        .filter(([error]) => field[error] === false)
        .map(([, message]) => message)
    : [];

export function errors(
  field?: Validation,
  name = "Field",
  map: { [error: string]: string } = {}
) {
  return mapErrors(field, {
    required: `${name} is required`,
    unique: `${name} already exists`,
    email: "Invalid email address",
    valid: `Invalid ${name}`,
    validId: "Id can only contain letter, numbers and underscores",
    integer: `${name} must be a whole number`,
    decimal: `${name} should be a number`,
    positiveNumber: `${name} must be a positive`,
    positiveNumberAndZero: `${name} must be a positive`,
    maxLength: `${name} exceeds maximum length`,
    ...map
  });
}

export function useFieldValiditySetup(field: string) {
  const isValid: Ref<boolean | undefined> = ref(undefined);

  function setInvalid(value: boolean) {
    isValid.value = !value;
  }

  return {
    [`${field}Valid`]: isValid,
    [`set${capitalize(field)}Invalid`]: setInvalid
  };
}

export function useFieldValidation(field: string) {
  return {
    [`${field}Valid`]: {
      required,
      valid: isTrue
    }
  };
}
