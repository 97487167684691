import { useTrackingUser } from "~/use/useFireAuth";
import useGTM from "~/use/useGTM";
// eslint-disable-next-line no-undef
export default defineNuxtPlugin(context => {
  const {
    router
  } = context.nuxt2Context.app;
  const {
    uid
  } = useTrackingUser();
  const {
    pushEvent
  } = useGTM(context.nuxt2Context.app);
  if (!router) {
    throw new Error("This plugin requires router");
  }
  async function onRoute(to, from) {
    var _document, _document2;
    await new Promise(r => setTimeout(r, 50));
    if (!uid.value && window.localStorage.getItem("isLoggedIn")) {
      onRoute(to, from);
      return;
    }
    const fallbackPreviousUrl = ((_document = document) === null || _document === void 0 ? void 0 : _document.referrer) || window.localStorage.getItem("previousUrl") || null;
    window.localStorage.setItem("previousUrl", `${window.location.origin}${to.fullPath}`);
    pushEvent("virtualPageview", {
      pageTitle: ((_document2 = document) === null || _document2 === void 0 ? void 0 : _document2.title) || "",
      pageUrl: `${window.location.origin}${to.fullPath}`,
      previousUrl: from.fullPath === "/" ? fallbackPreviousUrl : `${window.location.origin}${from.fullPath}`
    }, 0);
  }
  router.afterEach(onRoute);
});