import { IJwtInfo } from "../stores/auth/auth.interface";
import { useRouter as useCompositionRouter } from "@nuxtjs/composition-api";
import RoutesMap from "../routes/index.route";
import useAuthStore from "../stores/auth/auth.store";

function useRouter(context) {
  function routerMapper(route: string, permissions: Array<number>) {
    if (!route || !permissions?.length) return false;
    const permissionByRoute = RoutesMap.find((r) => r.paths.includes(route));
    if (!permissionByRoute) return false;
    if (permissionByRoute.allowAccessAll) return true;
    const hasPermission = permissions.some((p) =>
      permissionByRoute.permissions.includes(p)
    );
    return hasPermission;
  }

  function validatePermissionByRoute() {
    const router = useCompositionRouter();
    const { route } = context;
    const authStore = useAuthStore();
    const { jwtInfo }: { jwtInfo: IJwtInfo } = authStore.getAuthState;

    let hasPathId = route.value.params.partnerId
      ? route.value.path.replaceAll(route.value.params.partnerId, ":partnerId")
      : route.value.path;
    hasPathId = hasPathId.replaceAll(route.value.params.id, ":id");
    hasPathId = hasPathId.replace(/\/$/, "");

    const hasPermissionsToNavigate = routerMapper(
      hasPathId ?? route.value.path,
      jwtInfo.permissions as Array<number>
    );
    if (!hasPermissionsToNavigate) {
      router.push("/unauthorized");
    }
  }

  function validateMenuPermissionByRoute(route: string) {
    const authStore = useAuthStore();
    const { jwtInfo }: { jwtInfo: IJwtInfo } = authStore.getAuthState;
    const hasPermissionsToNavigate = routerMapper(
      route,
      jwtInfo.permissions as Array<number>
    );
    return hasPermissionsToNavigate;
  }

  return {
    routerMapper,
    validatePermissionByRoute,
    validateMenuPermissionByRoute
  };
}

export default useRouter;
