/* eslint-disable import/no-extraneous-dependencies */
import { getApp, getApps, initializeApp } from "@firebase/app";
import { getAuth } from "@firebase/auth";
import { getFirestore } from "@firebase/firestore";

import firebaseConfig from "../../../firebase-client-config.json";

const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();

export const db = getFirestore(app);

export const auth = getAuth(app);

export default app;
