import { InsureLoadPageData, getCommodityNameFromId, iCoverageDisplayOption } from "@loadsure/core";
import { iItem } from "@loadsure/utils";
import { useContext } from "@nuxtjs/composition-api";
import { useTrackingUser } from "./useFireAuth";
import type { Context as NuxtContext } from "@nuxt/types";

export type TrackingEventData = {
  virtualPageview: {
    pageUrl: string
    pageTitle: string
    previousUrl: string | null
  }
  login: Record<string, unknown>
  logout: Record<string, unknown>
  ss_selectpolicyholder: Record<string, unknown>
  ss_selecttransportmethod: Record<string, unknown>
  ss_quote_instantprice: {
    transport_method?: string
    quote_price?: number
    premium?: number
    coverage_limit?: number
    currency?: string
    commodity?: string
    from_location?: string
    to_location?: string
  }
  ss_selectshipmentdetails: {
    transport_method?: string
  }
  ss_selectstops: {
    transport_method?: string
  }
  ss_insuredcompanyparties: {
    transport_method?: string
  }
  ss_quote_summary: {
    transport_method?: string
  }
  ss_saved_quote: {
    quote_id?: string
    transport_method?: string
    quote_price?: number
    premium?: number
    coverage_limit?: number
    currency?: string
    commodity?: string
    from_location?: string
    to_location?: string
  }
  ss_submittedforuwreview: {
    quote_id?: string
    transport_method?: string
    quote_price?: number
    premium?: number
    coverage_limit?: number
    currency?: string
    commodity?: string
    from_location?: string
    to_location?: string
  }
  ss_view_quote_list: {
    tab?: string
  }
  ss_view_quote: {
    quote_id?: string
    quote_status?: string
    transport_method?: string
  }
  ss_reject_quote: {
    quote_id?: string
    quote_status?: string
    transport_method?: string
    reject_reason?: string
  }
  ss_cancel_quote: {
    quote_id?: string
    quote_status?: string
    transport_method?: string
    cancel_reason?: string
  }
  ss_view_certificate_list: {
    tab?: string
  }
  ss_view_certificate: {
    certificate_id?: string
  }
  ss_view_certificate_pdf: {
    certificate_id?: string
  }
  ss_view_certificate_policypdf: {
    certificate_id?: string
  }
  ss_cancel_certificate: {
    certificate_id?: string
    cancel_reason?: string
  }
  purchase: {
    ecommerce: {
      transport_method?: string
      certificate_id?: string
      premium?: number
      coverage_limit?: number
      commodity?: string
      from_location?: string
      to_location?: string
      currency?: string
      value?: number
      tax?: number
      transaction_id?: string
      items?: {
        item_name?: string
        price?: number
        quantity?: number
      }[]
    }
  }
}

export type TrackingEvent<
  T extends keyof TrackingEventData = keyof TrackingEventData
> = {
    event: T
    user_id: string | null
    partner_type: string | null
} & TrackingEventData[T]

declare module "@nuxt/types" {
  interface Context {
    $gtm: {
      init: () => void
      push: (data: TrackingEvent | ((this: { reset: () => void }) => void)) => void
    }
  }
}

export default function useGTM(
  context: ReturnType<typeof useContext> | NuxtContext
) {
  function resetDataLayer() {
    context.$gtm.push(function reset() {
      this.reset();
    });
  }

  async function pushEvent<T extends keyof TrackingEventData>(
    event: T,
    data: TrackingEventData[T],
    delay = 150
  ) {
      const {
        uid,
        newPartnerType
      } = useTrackingUser();

      const eventData: TrackingEvent<T> = {
        event,
        user_id: uid.value,
        partner_type: newPartnerType.value,
        ...data
      };

      await new Promise(r => setTimeout(r, delay));

      context.$gtm.push(eventData);
      if (event === "logout") resetDataLayer();
    }

    function getTransportMethod(transport?: string, truckload?: string) {
      return transport && truckload
        ? `${transport}_${truckload}`.toLowerCase()
        : transport?.toLowerCase();
    }

    function getCommodity(
      options?: iCoverageDisplayOption[],
      insureLoad?: InsureLoadPageData
    ) {
      const fromOptions = options
        ?.find(c => c.id === "commodity")
        ?.items
        .filter(c => insureLoad?.coverage?.coverageParameters?.commodity
          ?.includes((c as iItem<string>).value))
        .map(c => (c as iItem<string>).text)
        ?.[0];

      const fromLoad = getCommodityNameFromId(
        insureLoad?.shipment?.cargo?.commodities[0] || -1);

      return (fromOptions || fromLoad)?.toLowerCase();
    }

    return {
      resetDataLayer,
      pushEvent,
      getTransportMethod,
      getCommodity
    };
}
